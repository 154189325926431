<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-toolbar elevation="2" style="background: #f5f5f5">
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                    Shared Product From Mother Site
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pr-5 pb-5 pt-5">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                </v-toolbar>

                <v-data-table
                  :headers="SellerHeader"
                  :items="orders"
                  :search="searchseller"
                  sort-by="id"
                  class="elevation-1"
                  style="background: #f5f5f5 elevation-1"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      style="padding-left: 0px !important"
                      text
                      @click="viewDetail(item)"
                      >Detail</v-btn
                    >
                  </template>

                  <!-- <template v-slot:item.sharemother="{ item }">
                    <v-btn text @click="ShareMS(item)" color="primary">
                      Share
                    </v-btn>
                  </template> -->
                </v-data-table>
              </template>
            </v-col>
          </v-row>

          <!-- Mother site Shared Produdt deatails view show and Import dialog -->
          <v-dialog
            v-model="ShareProductmodel"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card class="mx-auto mt-12" elevation="0">
                  <v-row
                    v-if="
                      child_details.product_images &&
                      child_details.product_images.length > 0
                    "
                  >
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(slide, i) in child_details.product_images"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            child_details.product_id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Specification ID :
                          <span class="font-weight-regular">{{
                            child_details.id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          SKU :
                          <span class="font-weight-regular">{{
                            child_details.product_code.SKU
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            child_details.product_data.title
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ child_details.product_data.brand }}
                          </span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular"
                            >{{ child_details.warranty }}
                            {{ child_details.warranty_unit }}</span
                          >
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Unit :
                          <span class="font-weight-regular">{{
                            child_details.unit
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            child_details.product_data.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            child_details.product_data.shipping_country
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ child_details.product_data.description }}
                      </v-col>

                      <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(itemfeatures, index) in child_details
                              .product_data.key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        Color :

                        {{ child_details.color }}
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        Size :

                        {{ child_details.size }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-card>

            <v-card class="pa-5">
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Price Assign For Share Product</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header height="250px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Quantity</th>
                        <th class="text-left">Status</th>
                        <th class="text-center">Purchase Price</th>
                        <!-- <th class="text-center">Selling Price</th> -->
                        <!-- <th class="text-left">Action</th> -->
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr
                        v-for="(item, index) in child_details.max_min"
                        :key="index"
                      >
                        <td class="text-left">{{ item.quantity }}</td>
                        <td class="text-left">{{ item.status }}</td>
                        <td>{{ item.selling_price }}</td>

                        <!--                      
                        <td>
                          <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.mother_selling_price"
                            ></v-text-field>
                          </v-form>
                        </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <v-card-actions>
                <v-row>
                  <v-col cols="5" class="d-flex flex-row">
                    <p
                      v-if="
                        child_details.max_min &&
                        child_details.max_min.length > 0
                      "
                      class="mt-5 mr-2 font-weight-medium d-flex flex-row"
                    >
                      MRP : {{ child_details.max_min[0].mrp }}
                    </p>
                    <v-text-field
                      outlined
                      type="number"
                      style="max-width: 100px; height: 50px"
                      class="mt-2"
                      flat
                      dense
                      required
                      v-model="child_details.input_mrp"
                      label="New MRP"
                    ></v-text-field>
                    <!-- <v-text-field
                      outlined
                      type="number"
                      style="max-width: 150px; height: 50px"
                      class="mt-2"
                      flat
                      dense
                      v-model="mrp"
                    ></v-text-field> -->
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="d-flex flex-row mt-2">
                    <!-- <v-btn elevation="0" color="#854FFF" class="pt-2" @click="UpdateShare">
                      Update And Share
                    </v-btn> -->
                    <v-btn
                      elevation="0"
                      color="red"
                      @click="CancelApprove(child_details)"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      elevation="0"
                      color="primary"
                      @click="Import(child_details)"
                    >
                      Import
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-row>

      <v-snackbar v-model="snackbar" centered :color="color">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>



<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    editdialog: false,
    ShareProductmodel: false,
    loading: true,
    searchseller: "",
    snackbar:false,
    color:'',
    text:"",

    SellerHeader: [
      { text: "SKU", value: "product_code.SKU" },
      { text: "Product Title", value: "product_data.title" },
      { text: "Brand ", value: "product_data.brand" },
      { text: "Color ", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "View", value: "actions", sortable: false },
      // { text: 'test', value: 'tes' },
      // { text: "", value: "data-table-expand" },
    ],
    child_details: {
      product_data: {},
      category_data: {},
      product_code: {},
      max_min: [],
      input_mrp: 0,
    },

    tickets: [],
    contact: {},

    orders: [],

    sellerPdetails: {},
  }),

  methods: {
    initialize() {
      axios.get("productdetails/all_mothersite_products/").then((response) => {
        this.orders = response.data.data;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    },

    closedialog() {
      this.dialog = false;
    },
    viewDetail(item) {
      this.ShareProductmodel = true;
      Object.assign(this.child_details, item);
    },
    Import(child_details) {
      //console.log("this is approved data after confirm", child_details.child_max_min)
      child_details.max_min.forEach((element) => {
        (element.purchase_price = element.selling_price),
          (element.MRP = parseFloat(child_details.input_mrp));
      });
      //console.log("element array", this.child_details.max_min)
      axios
        .post(
          `productdetails/bring_product/${this.child_details.id}/`,
          this.child_details.max_min
        )
        .then((response) => {
          if (response.data.success) {
            this.orders = this.orders.filter(
              (contact) => contact.id != child_details.id
            );
            this.ShareProductmodel = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
          } else {
            this.ShareProductmodel = false;
            //this.text = "something Want Wrong!";
            this.text = response.data.message;
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";

          this.color = "error";
          this.snackbar = true;
        });
    },
    // viewDetail(item) {
    //   this.dialog = true;
    //   this.sellerPdetails = item;
    // },
    closedialog() {
      this.ShareProductmodel = false;
    },
    CancelApprove() {
      this.ShareProductmodel = false;
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>



<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>